import React, { useState, useEffect } from "react";
import SideModal from "./SideModal";
import HealthIcon from "assets/svgs/health-icon.svg";
import PassBenefit from "assets/svgs/pass-benefit.svg";
import GymBenefit from "assets/svgs/gym-benefit.svg";
import { CheckCard } from "components/forms";
import { useHistory } from "react-router-dom";
import {
    benefitPayment,
    benefitPaymentWithWallet,
    payBenefitWithToken,
    getBenefitDetail,
    changeBenefitPayment,
    changeBenefitPaymentWithWallet,
    changeBenefitPaymentWithToken,
} from "services/benefits";
import { fmtCurrency, returnDuration, useQuery } from "utils/functions";
import toast from "react-hot-toast";
import { getUserPaymentDetails } from "services/payment";
import { getWallet } from "services/wallet";
import Secure from "assets/svgs/secure.svg";
import Icon from "components/Icon";
import IllustrationLoader from "components/IllustrationLoader";
import {
    renewPassPayment,
    renewPassWithToken,
    renewPassWithWallet,
    changePassPayment,
    changePassWithToken,
    changePassWithWallet,
    payFlancePassWithToken,
    payFlancePassWithWallet,
    purchaseFlancePass,
} from "services/flancepass";

const SecureIcon = () => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Secure} alt="" style={{ marginRight: "8px" }} />
            <span> Secured by Flutterwave</span>
        </div>
    );
};

const CardMethod = ({ userCard }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Icon
                id={userCard?.cardType.toLowerCase()} // id can be mastercard, visacard or vervecard
                className="card-type"
                width="21"
                height="16"
            />
            {""}
            <span className="ml-16">
                {userCard?.cardFirst6digits} **** ****
            </span>
        </div>
    );
};

export default function PurchaseModal({
    benefit,
    openPurchaseModal,
    setOpenPurchaseModal,
    flancePass,
    renewal,
}) {
    const history = useHistory();
    const [paymentFrequency, setPaymentFrequency] = useState([]);
    const [selectedPaymentFrequency, setSelectedPaymentFrequency] =
        useState(null);
    const [validatePayment, setValidatePayment] = useState(false);
    const [success, setSuccess] = useState(false);
    const [benefitDetail, setBenefitDetail] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [tokenSuccessData, setTokenSuccessData] = useState(null);
    const [tokenLoading, setTokenLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [payMethod, setPayMethod] = useState([
        {
            duration: "Pay with Flutterwave",
            subtext: <SecureIcon />,
            method: "bank",
        },
    ]);
    const query = useQuery();

    const userBenefitId = query.get("userBenefitId");
    const changeBenefit = query.get("change");
    const isBusibessBenefit = query.get("isBusibessBenefit");
    const changePass = query.get("changePass");

    const [paymentButtonText, setPaymentButtonText] = useState("");

    const user = JSON.parse(localStorage.getItem("user"));
    const selectPaymentFrequency = (e) => {
        paymentFrequency.forEach((el) => {
            if (el.duration === e.target.value) {
                setSelectedPaymentFrequency(el);
            }
        });
    };
    const fetchBenefit = async () => {
        try {
            const response = await getBenefitDetail(benefit.id);
            setBenefitDetail(response.data);
        } catch (error) {
            toast.error("Error fetching benefit details");
        }
    };
    useEffect(() => {
        if (benefit) {
            fetchBenefit();
        }
    }, [benefit]);

    useEffect(() => {
        setPaymentFrequency([]);
        const durations = [
            "Monthly",
            "Quarterly",
            "Annually",
            "Weekly",
            "Daily",
        ];
        if (benefitDetail) {
            let payFreq = [];
            durations.forEach((duration) => {
                const found = benefitDetail?.priceOptions.find(
                    (priceOption) =>
                        priceOption.duration.toLowerCase() ===
                        duration.toLowerCase()
                );
                if (found) {
                    payFreq.push({
                        duration: duration,
                        subtext: `${fmtCurrency(
                            found.price
                        )} per ${returnDuration(duration)}`,
                        amount: found.price,
                        disabled: false,
                    });
                } else {
                    if (duration === "Daily" || duration === "Weekly") return;
                    payFreq.push({
                        duration: duration,
                        subtext: `${fmtCurrency(0)} per ${returnDuration(
                            duration
                        )}`,
                        amount: 0,
                        disabled: true,
                    });
                }
            });
            setPaymentFrequency(payFreq);
        } else if (flancePass) {
            setSelectedPaymentFrequency({
                duration: flancePass.duration,
                subtext: `${fmtCurrency(flancePass.price)} per ${returnDuration(
                    flancePass.duration
                )}`,
                amount: flancePass.price,
                disabled: false,
            });
        }
    }, [benefitDetail, flancePass]);
    const redirectHome = () => {
        window.location = "/";
    };

    let payload;

    if (benefit) {
        payload = {
            benefitId: benefit.id,
            redirectUrl: window.location.origin,
            amount: selectedPaymentFrequency?.amount,
            customizations: {
                title: changeBenefit ? "Change benefit" : "Benefit purchase",
                logo: "https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png",
            },
            duration: selectedPaymentFrequency?.duration.toUpperCase(),
        };
    } else if (flancePass) {
        payload = {
            passId: flancePass.id,
            redirectUrl: window.location.origin,
            customizations: {
                title: "Flance pass purchase",
                logo: "https://res.cloudinary.com/flance/image/upload/v1666684602/logo/logo_bg_nc1svk.png",
            },
            isRenewal: renewal,
        };
    }

    const makeFlancePassPayment = async () => {
        try {
            const response = await purchaseFlancePass(payload);
            window.location = response.data.data.link;
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            return error;
        } finally {
        }
    };

    const makeFlancePassPaymentWithToken = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await payFlancePassWithToken(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error.response);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeFlancePassPaymentWithWallet = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await payFlancePassWithWallet(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeFlancePassChange = async () => {
        try {
            const response = await changePassPayment(payload);
            window.location = response.data.data.link;
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            return error;
        } finally {
        }
    };

    const makeFlancePassChangeWithToken = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await changePassWithToken(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error.response);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeFlancePassChangeWithWallet = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await changePassWithWallet(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeFlancePassRenew = async () => {
        try {
            const response = await renewPassPayment(payload);
            window.location = response.data.data.link;
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            return error;
        } finally {
        }
    };

    const makeFlancePassRenewWithToken = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await renewPassWithToken(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error.response);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeFlancePassRenewWithWallet = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await renewPassWithWallet(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makePayment = async () => {
        try {
            const response = await benefitPayment(payload);
            window.location = response.data.data.link;
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            return error;
        } finally {
        }
    };

    const makePaymentWithToken = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await payBenefitWithToken(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error.response);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makePaymentWithWallet = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await benefitPaymentWithWallet(payload);
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeChangePayment = async () => {
        try {
            const response = await changeBenefitPayment(
                payload,
                userBenefitId,
                isBusibessBenefit
            );
            window.location = response.data.data.link;
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
            return error;
        }
    };

    const makeChangePaymentWithToken = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await changeBenefitPaymentWithToken(
                payload,
                userBenefitId,
                isBusibessBenefit
            );
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error.response);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const makeChangePaymentWithWallet = async () => {
        setValidatePayment(false);
        setTokenLoading(true);
        try {
            const response = await changeBenefitPaymentWithWallet(
                payload,
                userBenefitId,
                isBusibessBenefit
            );
            setTokenSuccessData(response.data);
        } catch (error) {
            console.error(error);
            toast.error(error.response.data.message);
        } finally {
            setTokenLoading(false);
        }
    };

    const paymentConfirmation = async () => {
        if (history.location.search || tokenSuccessData) {
            const payload = {
                status: tokenSuccessData.status,
                txRef: tokenSuccessData.tx_ref,
                transaction_id: tokenSuccessData.transaction_id,
            };
            if (
                payload.status === "successful" ||
                payload.status === "success"
            ) {
                setSuccess(true);

                setTimeout(() => {
                    redirectHome();
                }, 2000);
            } else {
                toast.error("Payment failed");
            }
        }
    };

    const fetchWallet = async () => {
        try {
            const response = await getWallet();
            setWallet(response.data);

            if (response.data) {
                const wallet = {
                    duration: "Pay with Flance Wallet",
                    subtext: `Your balance: ${fmtCurrency(
                        response.data?.balance
                    )}`,
                    method: "wallet",
                };
                const exists = payMethod.some(
                    (item) => item.method === wallet.method
                );
                if (!exists)
                    setPayMethod((payMethod) => [...payMethod, wallet]);
            }
        } catch (error) {
            if (error?.response?.status !== 404) {
                console.error(error.response);
            }
        }
    };

    const getPaymentCard = async () => {
        try {
            const response = await getUserPaymentDetails();
            if (response.data) {
                const card = {
                    duration: "Pay with Card",
                    subtext: <CardMethod userCard={response.data} />,
                    method: "card",
                };
                const exists = payMethod.some(
                    (item) => item.method === card.method
                );
                if (!exists) setPayMethod((payMethod) => [...payMethod, card]);
            }
        } catch (error) {
            return error;
        }
    };

    const selectPaymentMethod = (e) => {
        payMethod.forEach((el) => {
            if (el.duration === e.target.value) {
                setPaymentMethod(el);
            }
        });
    };

    const proceedPayment = () => {
        if (validatePayment || flancePass) {
            if (flancePass && !renewal) {
                if (changePass) {
                    if (paymentMethod?.method === "wallet") {
                        if (
                            wallet?.balance >= selectedPaymentFrequency?.amount
                        ) {
                            makeFlancePassChangeWithWallet(true);
                        } else {
                            toast.error("Insufficient balance on wallet");
                        }
                    } else if (paymentMethod?.method === "bank") {
                        makeFlancePassChange();
                    } else if (paymentMethod?.method === "card") {
                        makeFlancePassChangeWithToken();
                    }
                } else {
                    if (paymentMethod?.method === "wallet") {
                        if (
                            wallet?.balance >= selectedPaymentFrequency?.amount
                        ) {
                            makeFlancePassPaymentWithWallet(true);
                        } else {
                            toast.error("Insufficient balance on wallet");
                        }
                    } else if (paymentMethod?.method === "bank") {
                        makeFlancePassPayment();
                    } else if (paymentMethod?.method === "card") {
                        makeFlancePassPaymentWithToken();
                    }
                }
            } else if (flancePass && renewal) {
                if (paymentMethod?.method === "wallet") {
                    if (wallet?.balance >= selectedPaymentFrequency?.amount) {
                        makeFlancePassRenewWithWallet(true);
                    } else {
                        toast.error("Insufficient balance on wallet");
                    }
                } else if (paymentMethod?.method === "bank") {
                    makeFlancePassRenew();
                } else if (paymentMethod?.method === "card") {
                    makeFlancePassRenewWithToken();
                }
            } else {
                if (changeBenefit) {
                    if (paymentMethod?.method === "wallet") {
                        if (
                            wallet?.balance >= selectedPaymentFrequency?.amount
                        ) {
                            makeChangePaymentWithWallet(true);
                        } else {
                            toast.error("Insufficient balance on wallet");
                        }
                    } else if (paymentMethod?.method === "bank") {
                        makeChangePayment();
                    } else if (paymentMethod?.method === "card") {
                        makeChangePaymentWithToken();
                    }
                } else {
                    if (paymentMethod?.method === "wallet") {
                        if (
                            wallet?.balance >= selectedPaymentFrequency?.amount
                        ) {
                            makePaymentWithWallet(true);
                        } else {
                            toast.error("Insufficient balance on wallet");
                        }
                    } else if (paymentMethod?.method === "bank") {
                        makePayment();
                    } else if (paymentMethod?.method === "card") {
                        makePaymentWithToken();
                    }
                }
            }
        } else if (showSuccessMessage) {
            setOpenPurchaseModal(false);
            redirectHome();
        } else {
            setValidatePayment(true);
        }
    };

    useEffect(() => {
        Promise.all([getPaymentCard(), fetchWallet()]);
    }, []);

    const setIcons = (category) => {
        switch (category) {
            case "Health Insurance":
                return HealthIcon;
            case "Gym":
                return GymBenefit;
            default:
                return PassBenefit;
        }
    };

    const paymentButtonTextMethod = () => {
        if (validatePayment || flancePass) {
            return `confirm and Pay ${
                paymentMethod?.method === "card"
                    ? "with saved card"
                    : paymentMethod?.method === "wallet"
                    ? "with Flance Wallet"
                    : paymentMethod?.method === "bank"
                    ? "with Flutterwave"
                    : ""
            }`;
        } else if (showSuccessMessage) {
            return "Go to home";
        } else if (tokenLoading || success) {
            return "";
        } else {
            return "Continue to Pay";
        }
    };

    useEffect(() => {
        setPaymentButtonText(paymentButtonTextMethod());
    }, [
        validatePayment,
        tokenLoading,
        success,
        showSuccessMessage,
        paymentMethod,
        flancePass,
    ]);

    useEffect(() => {
        if (success === true) {
            setTimeout(() => {
                setShowSuccessMessage(true);
            }, 2000);
        }
    }, [success]);

    useEffect(() => {
        if (tokenSuccessData) {
            paymentConfirmation();
        }
    }, [tokenSuccessData]);

    return (
        <SideModal
            btnText={paymentButtonText}
            headerText={benefit?.benefitName || flancePass?.name}
            openSideModal={openPurchaseModal}
            setOpenSideModal={setOpenPurchaseModal}
            btnDisbled={!selectedPaymentFrequency}
            isCart={false}
            handleClick={proceedPayment}
            closeSideModal={() => setOpenPurchaseModal(false)}
        >
            {tokenLoading || success ? (
                <>
                    {showSuccessMessage ? (
                        <div className="renewal__success">
                            <h3>
                                {benefit?.benefitName} purchased successfully!
                            </h3>
                            <Icon id="success" size="64" />
                            <div className="renewal__success-info">
                                <h5>Policy information</h5>
                                <p>
                                    You're now covered for{" "}
                                    {benefit?.benefitName} for the next{" "}
                                    {selectedPaymentFrequency?.duration}.
                                    Activate your policy to start enjoying the
                                    benefits.
                                </p>
                            </div>
                        </div>
                    ) : (
                        <IllustrationLoader
                            loadingTitle="Payment processing"
                            successTitle="Payment Successful 🎉"
                            loadingSubtext="Please wait while we process your payment"
                            successSubtext="Your payment is successful"
                            loading={tokenLoading}
                            success={success}
                        />
                    )}
                </>
            ) : (
                <>
                    {!validatePayment && !flancePass ? (
                        <>
                            <div className="renewal__form">
                                {flancePass ? (
                                    <div className="renewal__form-info">
                                        <div>
                                            <p className="benefitcard__value">
                                                {fmtCurrency(flancePass?.price)}
                                                <span>
                                                    /{flancePass?.duration}
                                                </span>
                                            </p>
                                        </div>
                                        {flancePass?.discount > 0 && (
                                            <div className="choosepass__purchase-rate">
                                                <p className="">
                                                    <span>
                                                        {flancePass?.discount}%
                                                    </span>
                                                    off standard rate
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="renewal__form-info">
                                        <div className="benefitcard__title">
                                            <img
                                                src={setIcons(
                                                    benefit?.benefitCategory
                                                )}
                                                alt={benefit?.benefitCategory}
                                                width="24px"
                                            />
                                            <p>{benefit?.benefitName}</p>
                                        </div>
                                        <div>
                                            <p className="benefitcard__label">
                                                Benefit amount
                                            </p>
                                            <p className="benefitcard__value">
                                                {fmtCurrency(
                                                    benefit?.defaultPrice
                                                )}
                                                <span>
                                                    /{benefit?.defaultDuration}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <p className="__subtext">
                                        Choose how you’d like to be billed.
                                    </p>
                                    <div className="__theForm">
                                        <div className="selectcard-grid">
                                            <CheckCard
                                                name="frequency"
                                                options={paymentFrequency}
                                                onChange={(e) => {
                                                    selectPaymentFrequency(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="renewal__form">
                            <div className="back-btn">
                                <button
                                    type="button"
                                    onClick={() => setValidatePayment(false)}
                                >
                                    <Icon id="go-back" width="14" height="16" />
                                    Back
                                </button>
                            </div>

                            <div className="confirm-deets-modal__price-banner mx-auto mt-24">
                                <p className="confirm-deets-modal__title">
                                    You're about to pay
                                </p>
                                <h3 className="__top--value">
                                    <span className="">
                                        {fmtCurrency(
                                            selectedPaymentFrequency?.amount
                                        )}
                                    </span>
                                </h3>
                            </div>
                            <ul className="confirm-deets-modal__list">
                                <li className="confirm-deets-modal__list--deets">
                                    <p>Beneficiary</p>
                                    <p>{`${user.firstname} ${user.lastname}`}</p>
                                </li>
                                <li className="confirm-deets-modal__list--deets">
                                    <p>Cycle</p>
                                    <p>{selectedPaymentFrequency?.duration}</p>
                                </li>
                            </ul>
                            <div style={{ marginTop: "36px" }}>
                                <p className="__subtext">
                                    Choose how you’d like to pay.
                                </p>
                                <div className="__theForm">
                                    <div className="selectcard-grid paymentMtd-grid">
                                        <CheckCard
                                            name="paymentType"
                                            options={payMethod}
                                            onChange={(e) =>
                                                selectPaymentMethod(e)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </SideModal>
    );
}
